<template>
  <TTView>
    <VRow>
      <VCol>
        <h1>Checkbox & Radiobuttons</h1>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <h3>Checkbox TT</h3>
      </VCol>
    </VRow>

    <VRow>
      <VCol lg="4">
        <TTCheckbox
          v-for="(option, i) in options"
          :key="i"
          :label="option.title"
          :indeterminate="option.indeterminate"
          :disabled="option.disabled"
          :input-value="option.checked"
        />
        <TTCheckbox>
          <template #label>
            <span class="tt-light-green--text">
              Label slot
            </span>
          </template>
        </TTCheckbox>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <h3>Radiobutton TT</h3>
      </VCol>
    </VRow>

    <VRow>
      <VCol lg="4">
        <TTRadioGroup v-model="radioGroup">
          <TTRadio
            v-for="(option, i) in radioOptions"
            :key="i"
            :label="option.title"
            :value="i === 4 ? 1 : i"
          />
          <TTRadio>
            <template #label>
              <div>
                <span class="tt-light-green--text">Label slot</span>
              </div>
            </template>
          </TTRadio>
        </TTRadioGroup>

        <TTRadioGroup v-model="radioGroupUnactive">
          <TTRadio
            label="Option"
            disabled
          />
          <TTRadio
            label="Option"
            :value="1"
            disabled
          />
        </TTRadioGroup>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'Checkbox',

  data() {
    return {
      radioGroup: 1,
      radioGroupUnactive: 1,
      options: [
        {
          title: 'Option',
          disabled: false,
          indeterminate: true,
        },
        {
          title: 'Option',
          disabled: false,
          checked: false,
        },
        {
          title: 'При необходимости переноса текста \n'
            + 'на другую строчку, чекбокс будет \n'
            + 'вести себя вот таким образом',
          disabled: false,
          checked: true,
        },
        {
          title: 'Option',
          disabled: true,
          checked: false,
        },
        {
          title: 'Option',
          disabled: true,
          checked: true,
        },
      ],
      radioOptions: [
        {
          title: 'Option',
        },
        {
          title: 'Option',
        },
        {
          title: 'При необходимости переноса текста \n'
            + 'на другую строчку, радиобаттон будет \n'
            + 'вести себя вот таким образом',
        },
      ],
    };
  },
};
</script>
